import {
  Grid,
  Tab,
  Tabs,
  Typography
  } from '@material-ui/core';
import GatsbyLink from 'gatsby-link';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import styled from 'styled-components';
import AcademyImage from '../images/academie.jpg';
import DashboardImage from '../images/analytics.jpg';
import ChallengesImage from '../images/hackathon.jpg';
import IdeaboxImage from '../images/idea.jpg';
import IntraImage from '../images/intra.jpg';
import F1Img from '../images/new/fonction1.jpg';
import F2Img from '../images/new/fonction2.jpg';
import F3Img from '../images/new/fonction3.jpg';
import F4Img from '../images/new/fonction4.jpg';
import F5Img from '../images/new/fonction5.jpg';
import F6Img from '../images/new/fonction6.jpg';
import F7Img from '../images/new/fonction7.jpg';
import OpenInnoImage from '../images/openinno.jpg';
import ProjectImage from '../images/projet.jpg';
import theme from '../theme';

const BuildingTab = styled(Tab)`
  &&& {
    min-width: 140px;
    min-height: 40px;
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    white-space: nowrap;
  }
`;

const Link = styled(GatsbyLink)`
  font-family: 'Roboto';
  color: ${theme.palette.primary.main};
  font-size: 18px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomTabs = styled(Tabs)`
  && {
    ${theme.breakpoints.up('md')} {
      > div {
        overflow: -moz-hidden-unscrollable !important;
        scrollbar-width: none;
      }
    }
    *::-webkit-scrollbar {
      height: 0px;
      width: 0px;
      background-color: transparent;
    }
  }
`;

const Arrow = styled.div`
  cursor: pointer;
  user-select: none;
  color: ${theme.palette.grey[300]};
  &:hover {
    color: ${theme.palette.grey[500]};
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div<{ selected: boolean }>`
  cursor: pointer;
  margin: 3px;
  background-color: ${p =>
    p.selected ? theme.palette.grey[600] : theme.palette.grey[300]};
  transition: background-color 0.2s;
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

// tslint:disable-next-line:prefer-array-literal
const buildingData: Array<{
  title: string;
  description: string;
  image: string;
  logo: string;
  link: string;
}> = [
  {
    title: 'Boite à idées',
    description:
      "Vos collaborateurs partagent leurs idées, problèmes ou inspirations. Simples réflexions ou innovations spectaculaires, le meilleur moyen de le savoir est de les partager.\n\nBoostez la créativité et l’inventivité au sein de votre structure. Identifiez et évaluez facilement vos futurs projets d'innovation.",
    image: F1Img,
    logo: IdeaboxImage,
    link: '/features/ideabox',
  },
  {
    title: 'Lab',
    description:
      'N’arrêtez pas vos démarches au stade d’idéation. Les meilleures idées peuvent être sélectionnées pour réaliser une étude afin de valider leur faisabilité et leur pertinence. Grâce aux compétences de chacun de vos collaborateurs, les idées continuent d’évoluer.',
    image: F2Img,
    logo: ProjectImage,
    link: '/features/lab',
  },
  {
    title: 'Intrapreneuriat',
    description:
      "Aidez vos collaborateurs à aller encore plus loin dans la concrétisation de leurs idées. Chaque équipe d'intrapreuneurs bénéficie d'un espace dédié et sécurisé ainsi que d’un coaching digital pour structurer et développer leurs innovations.\n\nEngagez vos collaborateurs tout en développant de nouvelles activités économiques pour votre entreprise.",
    image: F3Img,
    logo: IntraImage,
    link: '/features/intra',
  },
  {
    title: 'Académie',
    description:
      "Facilitez le partage d'expertise et de savoir-faire entre vos collaborateurs. Chacun d’eux peut créer sa propre formation et la diffuser à l'ensemble de l'entreprise. C’est un excellent moyen de créer une culture de partage et de digitaliser vos formations.\n\nOptimisez ainsi la formation dans votre entreprise grâce à des contenus digitaux sur-mesure. ",
    image: F4Img,
    logo: AcademyImage,
    link: '/features/academy',
  },
  {
    title: 'Challenges',
    description:
      'Faites appel à vos collaborateurs afin de trouver des solutions sur un sujet précis.\n\nProblématiques quotidiennes ou enjeux plus complexes de l’entreprise, vos collaborateurs sont les premiers à pouvoir aider.',
    image: F5Img,
    logo: ChallengesImage,
    link: '/features/challenges',
  },
  {
    title: 'Open Innovation',
    description:
      'Lancez vos challenges auprès de vos clients, fournisseurs, partenaires ou auprès de particuliers. Sélectionnez ainsi les meilleurs acteurs externes pour répondre à vos problématiques.',
    image: F6Img,
    logo: OpenInnoImage,
    link: '/features/openinno',
  },
  {
    title: 'Tableau de bord',
    description:
      'Pilotez facilement vos démarches d’innovation. Analysez d’un coup d’oeil les activités, les idées et projets les plus prometteurs. Identifiez les profils les plus innovants parmi vos collaborateurs.',
    image: F7Img,
    logo: DashboardImage,
    link: '/features/dashboard',
  },
];

const SwipeableViewsWithKeyboard = bindKeyboard(SwipeableViews);

@observer
class BuildingTabs extends React.Component {
  @observable
  public tab: number = 0;

  @action
  public onChange = (e: any, value: number) => {
    this.tab = value;
  }

  public render() {
    return (
      <div style={{ position: 'relative' }}>
        <CustomTabs
          scrollable
          scrollButtons="on"
          style={{ marginTop: '30px' }}
          value={this.tab}
          onChange={this.onChange}
          textColor="primary"
          fullWidth
          indicatorColor="primary"
        >
          <BuildingTab label="Boite à idées" disableRipple />
          <BuildingTab label="Lab" disableRipple />
          <BuildingTab label="Intrapreneuriat" disableRipple />
          <BuildingTab label="Académie" disableRipple />
          <BuildingTab label="Challenges" disableRipple />
          <BuildingTab label="Open Innovation" disableRipple />
          <BuildingTab label="Tableau de bord" disableRipple />
        </CustomTabs>
        <div
          style={{
            width: '100%',
            marginTop: '-10px',
            borderTop: '2px solid rgba(0, 0, 0, 0.1)',
          }}
        />
        {this.tab !== 0 && (
          <Arrow
            onClick={() =>
              runInAction(() => {
                this.tab -= 1;
              })
            }
            className="desktop-only"
            style={{ position: 'absolute', top: '50%', left: '-80px' }}
          >
            <i className="fas fa-angle-left fa-4x" />
          </Arrow>
        )}
        {this.tab !== buildingData.length - 1 && (
          <Arrow
            onClick={() =>
              runInAction(() => {
                this.tab += 1;
              })
            }
            className="desktop-only"
            style={{ position: 'absolute', top: '50%', right: '-80px' }}
          >
            <i className="fas fa-angle-right fa-4x" />
          </Arrow>
        )}
        <SwipeableViewsWithKeyboard
          index={this.tab}
          onChangeIndex={(index: number) => this.onChange(null, index)}
          slideStyle={{ overflow: 'hidden' }}
        >
          {buildingData.map(data => (
            <Grid
              container
              spacing={32}
              alignItems="center"
              justify="space-between"
              style={{
                marginTop: '10px',
                padding: '30px',
                boxSizing: 'border-box',
              }}
            >
              <Grid xs={12} md={6}>
                <img
                  src={data.logo}
                  style={{
                    height: '80px',
                    width: '80px',
                    marginBottom: '15px',
                  }}
                />
                <Typography
                  style={{
                    fontSize: '25px',
                    fontWeight: 500,
                    fontFamily: 'Quicksand',
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  style={{
                    whiteSpace: 'pre-wrap',
                    fontSize: '16px',
                    fontWeight: 300,
                    marginTop: '7px',
                  }}
                >
                  {data.description}
                </Typography>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <Link to={data.link}>En savoir plus</Link>
                </div>
              </Grid>
              <Grid xs={12} md={5}>
                <img
                  src={data.image}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </SwipeableViewsWithKeyboard>
        <Dots>
          {buildingData.map((data, i) => (
            <Dot
              selected={i === this.tab}
              onClick={() =>
                runInAction(() => {
                  this.tab = i;
                })
              }
            />
          ))}
        </Dots>
      </div>
    );
  }
}

export default BuildingTabs;
