import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import theme from '../theme';

const Wrapper = styled.div<{ color: string }>`
  border-left: 4px solid ${p => p.color};
  padding-left: 20px !important;
  font-family: Roboto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 60px !important;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 15px !important;
  }
`;

const Item = styled(Grid)`
  ${theme.breakpoints.down('sm')} {
    padding: 10px;
  }
`;

interface Props {
  title: string;
  color: string;
  description: string;
}

class SizeItem extends React.PureComponent<Props> {
  public render() {
    return (
      <Item item xs={12} md={6}>
        <Wrapper color={this.props.color}>
          <span
            style={{
              color: this.props.color,
              fontSize: '25px',
              fontWeight: 500,
            }}
          >
            {this.props.title}
          </span>
          <p
            style={{
              marginTop: '7px',
              marginBottom: '7px',
              fontSize: '17px',
              fontWeight: 300,
              color: theme.palette.grey[700],
            }}
          >
            {this.props.description}
          </p>
        </Wrapper>
      </Item>
    );
  }
}

export default SizeItem;
