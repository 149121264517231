import {
  Button,
  Fade,
  Grid,
  Paper,
  Typography
  } from '@material-ui/core';
import { PlayCircleFilledWhite } from '@material-ui/icons';
import { Link } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import BuildingTabs from '../components/BuildingTabs';
import Layout from '../components/Layout';
import SizeItem from '../components/SizeItem';
import LogoIrt from '../images//irt.png';
import ArnaudImg from '../images/arnaud.png';
import MatchingImg from '../images/new/assurance1.jpg';
import ResponsiveImg from '../images/new/assurance2.jpg';
import SecureImg from '../images/new/assurance3.jpg';
import Benef1Img from '../images/new/benef1_rose.jpg';
import Benef2Img from '../images/new/benef2_vert.jpg';
import Benef3Img from '../images/new/benef3_jaune.jpg';
import Benef4Img from '../images/new/benef4_bleu.jpg';
import CampusImg from '../images/new/campus.jpg';
import Def1Img from '../images/new/def1_rose.jpg';
import Def2Img from '../images/new/def2_jaune.jpg';
import Def3Img from '../images/new/def3_vert.jpg';
import Def4Img from '../images/new/def4_bleu.jpg';
import Inno1Img from '../images/new/inno1.jpg';
import Inno2Img from '../images/new/inno2.jpg';
import Inno3Img from '../images/new/inno3.jpg';
import Inno4Img from '../images/new/inno4.jpg';
import LogoExcent from '../images/new/logo_excent.jpg';
import LogoInnov from '../images/new/logo_innovatech3v.jpg';
import LogoOcean from '../images/new/logo_oceanbleu.jpg';
import Play from '../images/new/play_button.png';
import theme from '../theme';

const videos = [
  {
    url: 'https://www.youtube.com/embed/6kcol50mI0s',
    title: "Qu'est ce que le paradigme d'ambidextrie des entreprises ?",
  },
  {
    url: 'https://www.youtube.com/embed/U2tbayJYqrg',
    title: "L'évolution des besoins face à ce paradigme",
  },
  {
    url: 'https://www.youtube.com/embed/Ry-c0FerfAQ',
    title: "L'importance de favoriser l'intrapreneuriat",
  },
  {
    url: 'https://www.youtube.com/embed/7HvPtf2Dh9k',
    title: 'Quel est le risque de ne pas innover ?',
  },
  {
    url: 'https://www.youtube.com/embed/bV5kxm0RqKg',
    title: 'La continuité entre le monde physique et le Digital',
  },
];

const Description = styled(Typography)`
  &&& {
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 17px;
    }
  }
`;

const breathing = keyframes`
  0% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }

  35% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }

  100% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }
`;

const PlayButton = styled.img`
  width: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  cursor: pointer;
  margin-top: -40px;
  margin-left: -40px;
  animation: ${breathing} 3s ease-out infinite normal;

  &:hover {
    animation: none;
    width: 80px;
  }
`;

const Title = styled(Typography)`
  &&&& {
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    ${theme.breakpoints.down('sm')} {
      font-size: 25px;
      padding: 0;
    }
  }
`;

const VideoItem = styled(Grid)`
  &&&& {
    ${theme.breakpoints.up('md')} {
      max-width: 20%;
      flex-basis: 20%;
    }
  }
`;

const JB = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    height: unset !important;
  }
`;

const QuadriItem: React.SFC<{
  image: string;
  title: string;
  description: string;
  color: string;
  height?: number;
}> = ({ image, title, description, color, height }) => (
  <Grid
    item
    xs={12}
    md={3}
    style={{
      padding: '0 30px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '60px',
    }}
  >
    <img
      src={image}
      style={{ height: '100px', margin: '0 auto', alignSelf: 'center' }}
    />
    <JB
      style={{
        color,
        height: height ? `${height}px` : undefined,
        marginTop: '10px',
        fontSize: '18px',
        fontFamily: 'Quicksand',
        fontWeight: 500,
      }}
    >
      {title}
    </JB>
    <Typography
      style={{
        color: theme.palette.blue.main,
        fontSize: '16px',
        fontWeight: 300,
        marginTop: '5px',
      }}
      align="justify"
    >
      {description}
    </Typography>
  </Grid>
);

const VideoWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
`;

const PlayButtonLarge = styled(Typography)`
  && {
    position: absolute;
    color: #ff0066;
    /* margin-top: -90px; */
    left: 50%;
    top: calc(50% + 50px);
    transform: translate(-50%);
    background-color: white;
    padding: 3px 7px;
    padding-top: 5px;
    border-radius: 25px;
    border: 1px solid #ff0066;
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: 600;

    &:hover {
      background-color: #ff0066;
      color: white;
    }
  }
`;

interface State {
  email: string;
}

@observer
class IndexPage extends React.Component<{}, State> {
  @observable
  public video: boolean = false;

  @observable
  public email: string = '';

  @action
  public updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.email = e.target.value;
  }

  public render() {
    return (
      <Layout>
        {this.video && (
          <Fade in>
            <VideoWrapper
              onClick={() =>
                runInAction(() => {
                  this.video = false;
                })
              }
            >
              <iframe
                className="desktop-only"
                src="https://player.vimeo.com/video/313171210?color=b6bdbf&byline=0"
                width="1024"
                height="720"
                frameBorder={0}
                allowFullScreen
              />
              <iframe
                className="mobile-only"
                src="https://player.vimeo.com/video/313175023?color=b6bdbf&byline=0"
                width="1024"
                height="300"
                frameBorder={0}
                allowFullScreen
              />
            </VideoWrapper>
          </Fade>
        )}
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Grid container spacing={16} alignItems="center">
            <Grid item xs={12} md={6}>
              <Title variant="display1" style={{ textAlign: 'left' }}>
                Manager votre innovation n'a jamais été aussi simple
              </Title>
              <Description
                align="justify"
                style={{
                  marginTop: '20px',
                  color: theme.palette.blue.main,
                  fontWeight: 300,
                }}
              >
                Engagez tous vos collaborateurs et boostez votre activité avec
                Campus, votre solution globale de management de l’innovation.
              </Description>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginTop: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <Button
                  style={{
                    margin: '5px',
                    flexGrow: 1,
                    fontSize: '15px',
                    textTransform: 'none',
                  }}
                  color="primary"
                  variant="contained"
                  component={(props: any) => (
                    <GatsbyLink
                      to="/demo"
                      {...props}
                      onClick={() => {
                        gtag_create();
                      }}
                    />
                  )}
                >
                  Essayer gratuitement Campus
                </Button>
                <Button
                  style={{
                    margin: '5px',
                    backgroundColor: theme.palette.green.main,
                    color: 'white',
                    fontSize: '15px',
                    flexGrow: 1,
                    textTransform: 'none',
                  }}
                  variant="contained"
                  component={(props: any) => (
                    <GatsbyLink
                      to="/contact"
                      {...props}
                      onClick={() => {
                        gtag_contact();
                      }}
                    />
                  )}
                >
                  Contacter notre équipe
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{ position: 'relative', marginLeft: 'auto' }}
            >
              <img
                src={CampusImg}
                style={{ width: '100%', maxWidth: '100%', marginTop: '20px' }}
              />
              <PlayButton
                onClick={() =>
                  runInAction(() => {
                    this.video = true;
                  })
                }
                src={Play}
              />
              <PlayButtonLarge
                onClick={() =>
                  runInAction(() => {
                    this.video = true;
                  })
                }
              >
                Voir la vidéo
              </PlayButtonLarge>
            </Grid>
          </Grid>
        </div>
        <div
          className="desktop-only"
          style={{
            marginTop: '100px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <img src={LogoExcent} style={{ height: '70px', margin: '20px' }} />
          <img src={LogoOcean} style={{ height: '70px', margin: '20px' }} />
          <img src={LogoInnov} style={{ height: '70px', margin: '20px' }} />
          <img src={LogoIrt} style={{ height: '70px', margin: '20px' }} />
        </div>
        {/* <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <img src={ArrowImg} style={{ width: '35px' }} />
        </div> */}
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          <Title style={{ fontSize: '25px' }}>
            Campus vous accompagne au quotidien
          </Title>
        </div>
        <Grid container spacing={40} style={{ marginTop: '30px' }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <img
              src={Inno2Img}
              style={{ height: '70px', width: '70px', marginRight: '40px' }}
            />
            <Typography
              color="primary"
              style={{
                fontSize: '21px',
                fontFamily: 'Quicksand',
                fontWeight: 500,
              }}
            >
              Développer de nouveaux produits
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <img
              src={Inno4Img}
              style={{ height: '70px', width: '70px', marginRight: '40px' }}
            />
            <Typography
              style={{
                fontSize: '21px',
                fontFamily: 'Quicksand',
                fontWeight: 500,
                color: theme.palette.green.main,
              }}
            >
              Améliorer en continu vos processus
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <img
              src={Inno1Img}
              style={{ height: '70px', width: '70px', marginRight: '40px' }}
            />
            <Typography
              color="secondary"
              style={{
                fontSize: '21px',
                fontFamily: 'Quicksand',
                fontWeight: 500,
              }}
            >
              Inventer de nouveaux services
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <img
              src={Inno3Img}
              style={{ height: '70px', width: '70px', marginRight: '40px' }}
            />
            <Typography
              style={{
                fontSize: '21px',
                fontFamily: 'Quicksand',
                fontWeight: 500,
                color: theme.palette.blue.main,
              }}
            >
              Booster l'engagement de vos collaborateurs
            </Typography>
          </Grid>
        </Grid>
        <div style={{ textAlign: 'center', marginTop: '60px' }}>
          <Button
            component={(props: any) => <Link to="/resources" {...props} />}
            style={{ backgroundColor: theme.palette.blue.main, color: 'white' }}
            variant="contained"
            size="large"
          >
            <PlayCircleFilledWhite
              style={{ marginRight: '7px', marginTop: '-2px' }}
            />{' '}
            Pourquoi adopter Campus ?
          </Button>
        </div>

        <div
          style={{
            textAlign: 'center',
            marginTop: '60px',
            marginBottom: '30px',
          }}
        >
          <Title style={{ fontSize: '25px' }}>
            Une solution complète qui regroupe les meilleurs outils
          </Title>
          <Typography
            style={{
              color: theme.palette.blue.main,
              fontSize: '18px',
              fontWeight: 300,
              marginTop: '10px',
            }}
          >
            Des activités pour toucher les différents profils de votre
            organisation, centraliser vos démarches d'innovation et améliorer en
            continu vos performances
          </Typography>
        </div>
        <BuildingTabs />
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Title style={{ fontSize: '25px' }}>
            Une nouvelle approche pour votre management de l'innovation
          </Title>
        </div>
        <Grid container spacing={40} style={{ marginTop: '40px' }}>
          <QuadriItem
            color={theme.palette.primary.main}
            image={Benef1Img}
            height={52}
            title="Engager tous vos collaborateurs"
            description="Tout le monde suit et contribue à vos démarches d'innovation. Identifiez
      les intrapreneurs de votre organisation."
          />
          <QuadriItem
            color={theme.palette.green.main}
            image={Benef2Img}
            height={52}
            title="Améliorer vos performances"
            description="Optimisez vos démarches d'amélioration continue et facilitez la transformation de vos idées en business pour votre entreprise."
          />
          <QuadriItem
            color={theme.palette.secondary.main}
            image={Benef3Img}
            height={52}
            title="Gagner du temps"
            description="Structurez, gérez et analysez simplement toutes vos démarches au quotidien."
          />
          <QuadriItem
            color={theme.palette.blue.main}
            image={Benef4Img}
            height={52}
            title="Faciliter l'adoption"
            description="Nous accompagnons votre déploiement et facilitons votre conduite de changement. La gamification et l'ergonomie de la solution facilitent l'adoption."
          />
        </Grid>
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          <Title style={{ fontSize: '25px' }}>
            Une solution adaptée à la taille de votre entreprise
          </Title>
          <Typography
            style={{
              color: theme.palette.blue.main,
              fontSize: '18px',
              fontWeight: 300,
              marginTop: '10px',
            }}
          >
            Parce que l'innovation est une question de survie, son management
            doit être accessible à tous.
          </Typography>
        </div>
        <Grid container spacing={32} style={{ marginTop: '70px' }}>
          <SizeItem
            color={theme.palette.primary.main}
            title="PME & ETI"
            description="Bénéficiez d'une solution complète pour renforcer vos démarches d'innovation en toute simplicité. "
          />
          <SizeItem
            color={theme.palette.secondary.main}
            title="Grand groupe"
            description="Élargissez et pérennisez vos démarches d'innovation en y intégrant tous vos collaborateurs. "
          />
          <SizeItem
            color={theme.palette.green.main}
            title="Startup"
            description="Participez à des challenges d'entreprises et accédez à de nouveaux marchés. "
          />
          <SizeItem
            color={theme.palette.blue.main}
            title="Incubateur & Fablab"
            description="Bénéficiez gratuitement de votre boîte à idée digitale et de votre académie pour partager vos expériences. "
          />
        </Grid>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            style={
              { margin: '10px', whiteSpace: 'nowrap' }
              // marginTop: '10px',
            }
            color="primary"
            variant="contained"
            component={(props: any) => (
              <GatsbyLink
                to="/demo"
                {...props}
                onClick={() => {
                  gtag_create();
                }}
              />
            )}
          >
            Essayer gratuitement Campus
          </Button>
          <Button
            style={
              {
                backgroundColor: theme.palette.green.main,
                color: 'white',
                whiteSpace: 'nowrap',
                margin: '10px',
              }
              // marginTop: '10px',
            }
            variant="contained"
            component={(props: any) => (
              <GatsbyLink
                to="/contact"
                {...props}
                onClick={() => {
                  gtag_contact();
                }}
              />
            )}
          >
            Contacter notre équipe
          </Button>
        </div>
        <div style={{ textAlign: 'center', marginTop: '70px' }}>
          <Title style={{ fontSize: '25px' }}>L'innovation est ...</Title>
        </div>
        <Grid container spacing={40} style={{ marginTop: '40px' }}>
          <QuadriItem
            color={theme.palette.primary.main}
            image={Def1Img}
            title="Une activité collective "
            description="qui résulte de la mise en commun des talents de vos collaborateurs. "
          />
          <QuadriItem
            color={theme.palette.secondary.main}
            image={Def2Img}
            title="Un levier indispensable"
            description="qui assure la pérennité et la compétitivité de votre organisation. "
          />
          <QuadriItem
            color={theme.palette.green.main}
            image={Def3Img}
            title="Une démarche complexe "
            description="qui doit être organisée comme une activité régulière à tous les niveaux. "
          />
          <QuadriItem
            color={theme.palette.blue.main}
            image={Def4Img}
            title="Un état d'esprit "
            description="qui a pour objectif le bien commun de l’entreprise et ses salariés."
          />
        </Grid>
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Title style={{ fontSize: '25px' }}>
            Pourquoi créer votre Campus de l'innovation ?
          </Title>
        </div>
        <Grid container spacing={40} style={{ marginTop: '20px' }}>
          <Grid item xs={12} md={8}>
            <Paper>
              <div style={{ padding: '15px' }}>
                <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                  <div
                    style={{
                      borderRadius: '3px',
                      overflow: 'hidden',
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      top: 0,
                    }}
                  >
                    <iframe
                      style={{ height: '100%', width: '100%' }}
                      src="https://www.youtube.com/embed/LHrDQdBom6M"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems="stretch"
            justify="center"
            direction="column"
            style={{ maxWidth: '400px', margin: '0 auto' }}
          >
            <img
              src={ArnaudImg}
              style={{ alignSelf: 'center', maxWidth: '120px' }}
            />
            <a
              style={{
                color: '#4875B4',
                marginTop: '20px',
                marginBottom: '20px',
                alignSelf: 'center',
              }}
              href="https://www.linkedin.com/in/arnaudgroff"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-2x fa-linkedin" />
            </a>
            <Typography
              align="justify"
              style={{
                fontWeight: 600,
                marginBottom: '10px',
                alignSelf: 'center',
                textAlign: 'center',
                color: theme.palette.blue.main,
              }}
            >
              Arnaud Groff
              <br />
              Président @ La Fabrique À Innovations
            </Typography>
            <Typography
              align="justify"
              style={{
                marginBottom: '10px',
                color: theme.palette.blue.main,
                fontWeight: 300,
              }}
            >
              Docteur-Ingénieur, Phd en management de l'innovation (ENSAM Paris)
              <br />
              Auditeur IHEST du ministère de la recherche
              <br />
              Expert APM
              <br />
              Auteurs de nombreux ouvrages chez DUNOD, AFNOR
              <br />
              Ancien délégué national à l'innovation du CJD
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={32}
          style={{ marginTop: '30px' }}
          justify="center"
        >
          <Button
            component={(props: any) => <Link to="/resources" {...props} />}
            style={{ backgroundColor: theme.palette.blue.main, color: 'white' }}
            variant="contained"
            size="large"
          >
            Voir les autres ressources vidéo
          </Button>
          {/* {videos.map((video, i) => (
            <VideoItem item xs={6} sm={6}>
              <Paper style={{ position: 'relative' }}>
                <div style={{ padding: '5px' }}>
                  <div
                    style={{
                      position: 'relative',
                      paddingTop: '56.25%',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '3px',
                        overflow: 'hidden',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        top: 0,
                      }}
                    >
                      <iframe
                        style={{
                          height: '100%',
                          width: '100%',
                        }}
                        src={video.url}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
              </Paper>
              <Typography
                style={{
                  padding: '0 10px',
                  textAlign: 'center',
                  fontSize: '15px',
                  color: theme.palette.grey[600],
                  marginTop: '15px',
                  fontWeight: 300,
                }}
              >
                {video.title}
              </Typography>
            </VideoItem>
          ))} */}
        </Grid>
        <Grid
          container
          spacing={40}
          style={{ marginTop: '70px' }}
          alignItems="flex-end"
        >
          <Grid
            item
            xs={12}
            md={4}
            style={{ maxWidth: '300px', margin: '0 auto' }}
          >
            <img
              src={MatchingImg}
              style={{ maxWidth: '100%', padding: '30px' }}
            />
            <Typography
              style={{
                color: theme.palette.blue.main,
                fontWeight: 600,
                fontSize: '18px',
                textAlign: 'center',
                marginBottom: '8px',
              }}
            >
              Solution évolutive
            </Typography>
            <Typography
              align="justify"
              style={{
                color: theme.palette.blue.main,
                fontSize: '17px',
                fontWeight: 300,
              }}
            >
              Le logiciel est mis à jour en continu avec les meilleures
              pratiques dans le domaine.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ maxWidth: '300px', margin: '0 auto' }}
          >
            <img
              src={ResponsiveImg}
              style={{ maxWidth: '100%', padding: '30px' }}
            />
            <Typography
              style={{
                color: theme.palette.blue.main,
                fontWeight: 600,
                fontSize: '18px',
                textAlign: 'center',
                marginBottom: '8px',
              }}
            >
              Accessible sur tous supports
            </Typography>
            <Typography
              align="justify"
              style={{
                color: theme.palette.blue.main,
                fontSize: '17px',
                fontWeight: 300,
              }}
            >
              Disponible sur smartphones et tablettes car les idées surgissent à
              tout moment.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ maxWidth: '300px', margin: '0 auto' }}
          >
            <img
              src={SecureImg}
              style={{ maxWidth: '100%', padding: '30px' }}
            />
            <Typography
              style={{
                color: theme.palette.blue.main,
                fontWeight: 600,
                fontSize: '18px',
                textAlign: 'center',
                marginBottom: '8px',
              }}
            >
              Espace sécurisé et confidentiel
            </Typography>
            <Typography
              align="justify"
              style={{
                color: theme.palette.blue.main,
                fontSize: '17px',
                fontWeight: 300,
              }}
            >
              Logiciel SaaS 100% sécurisé vous garantissant la confidentialité
              et l'intégrité de vos données.
            </Typography>
          </Grid>
        </Grid>
        {/* <div style={{ textAlign: 'center', marginTop: '150px' }}>
          <Title style={{ fontSize: '25px' }}>
            Libérez la créativité de vos collaborateurs et boostez votre
            innovation
          </Title>
        </div>
        <div style={{ textAlign: 'center', marginTop: '60px' }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
                    gtag_create();
                  }}
            component={(props: any) => <GatsbyLink to="/demo" {...props} />}
          >
            Essayer gratuitement Campus
          </Button>
        </div> */}
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          <Typography
            style={{
              fontSize: '20px',
              color: theme.palette.blue.main,
              fontWeight: 300,
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            Rendez-vous sur notre démonstrateur grand public où des milliers de
            personnes innovent déjà ensemble au quotidien sur{' '}
            <a
              href="https://lfai.co"
              target="_blank"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
              }}
            >
              www.lfai.co
            </a>
            .
          </Typography>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
